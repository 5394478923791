import { useRef } from "react";
import useState from "react-usestateref";

function PageFiltersState() {
    const [currentFilters, setCurrentFilters, currentFiltersRef] = useState()
    const [isApplyDisabled, setIsApplyDisabled] = useState(true);
    const scrollableContainerRef = useRef(null);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [isAtTop, setIsAtTop] = useState(true);
    const [filtersMappingArr, setFiltersMappingArr] = useState([]);
    const [showPageFilterModal, setPageFilterModal] = useState(false);
    const [filterDataList, setFilterDataList, filterDataListRef] = useState([]);
    const [structuredArr, setStructuredArr, structuredArrRef] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const [showData, setShowData] = useState(true);
    const [showMore, setShowMore] = useState(false);
    return {filteredData, setFilteredData,searchVal, setSearchVal, showData, setShowData, showMore, setShowMore, 
        showPageFilterModal, setPageFilterModal,setFilterDataList, 
        filterDataListRef,setStructuredArr, structuredArrRef,
        setCurrentFilters, currentFiltersRef, setIsApplyDisabled, isApplyDisabled, 
        scrollableContainerRef, isAtBottom, setIsAtBottom, isAtTop, setIsAtTop, 
        setFiltersMappingArr, filtersMappingArr }
}

export default PageFiltersState