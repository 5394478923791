class PageFiltersUtils {
    formatData = (setFiltersMappingArr,currentFiltersRef ) => {
        const groupsArr = currentFiltersRef.current?.map((el) => el.data).flat();
        const getHierarchyArr = currentFiltersRef.current?.map((el) => el.hierarchyList.length == 0 ? el.data.map((obj) => obj.group) : el.hierarchyList);
        let hirerarchyArr = [...new Set(getHierarchyArr.flat())];
        hirerarchyArr = hirerarchyArr.map((el) => groupsArr.filter((obj) => obj.group == el));
        setFiltersMappingArr(hirerarchyArr);
    }
    handleSelectedFilter = (obj, index, setCurrentFilters) => {
        setCurrentFilters((prevFilterDataList) => {
            const tempFilterData = [...prevFilterDataList];
            const data = [...tempFilterData[index].data];
            const dataIndex = data.findIndex((item) => item.id === obj.id);
            data[dataIndex].isSelected = !data[dataIndex].isSelected;
            tempFilterData[index].data = data;
            const selectedCount = data.reduce((count, item) => count + (item.isSelected ? 1 : 0), 0 );
            tempFilterData[index].selectedCount = selectedCount;
            return tempFilterData;
        });
    };
   
    deepCompare = (obj1, obj2) => {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    };
    handleScroll = (direction, scrollableContainerRef, setIsAtBottom, setIsAtTop) => {

      function checkScrollPosition (container, setIsAtBottom, setIsAtTop) {
        const isBottom = Math.round(container.scrollTop + container.clientHeight) >= container.scrollHeight;
        const isTop = container.scrollTop === 0;
        setIsAtBottom(isBottom);
        setIsAtTop(isTop);
    };
      if (scrollableContainerRef.current) {
          const container = scrollableContainerRef.current;
          container.scrollBy({
              top: direction === 'down' ? 100 : -100,
              behavior: 'smooth',
          });
          setTimeout(() => checkScrollPosition(container,setIsAtBottom, setIsAtTop ), 300);
      }
  };
    checkScrollPosition = (container, setIsAtBottom, setIsAtTop) => {
    const isBottom = Math.round(container.scrollTop + container.clientHeight) >= container.scrollHeight;
    const isTop = container.scrollTop === 0;
    setIsAtBottom(isBottom);
    setIsAtTop(isTop);
};

    changeLabelsAccordingToRequirement = (label) =>{
    const modifiedFieldsArr = ['Status', 'Asset Category Live'];
    if(modifiedFieldsArr.includes(label)){
        return label === modifiedFieldsArr[0] ? 'Asset Status' : 'Asset Category'
    }
    return label;
}
}

export default PageFiltersUtils;

