import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { API_BASE_URL } from "../../Constants/constants";
import { HelperFunctions } from "../../Utils/HelperFunctions";
import loader from "../../a1Components/lotties/round-loader-white.json";
import aoneIcon from "../../assets/icons/aone-logo.svg";
import atsuyaLogoName from "../../assets/icons/atsuya-logo-name.svg";
import eyeOff from "../../assets/icons/eyeOff-icon.svg";
import eyeOn from "../../assets/icons/eyeOn-icon.svg";
import sosImage from "../../assets/icons/sos-logo.svg";
import { useStateValue } from "../../redux/StateProvider";
import WronPage from "../ErrorPage/WronPage";
import "./LoginPageStyles.scss";
import { NavLink } from "react-router-dom";
import LoginSubLayout from "../Forgot Password/LoginSubLayout";
import LocalStorageService from "../../Utils/LocalStorageService";

function LoginPage({ Login, error, setLoginMsg, setIslogin }: any) {
  const [data, setData] = useState({
    username: "",
    password: "",
    token: "",
  });
  const [{ }, dispatch] = useStateValue();
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertShow, setAlertShow] = useState(errorMsg ? true : false);
  const [errorPage, setErrorPage] = useState(true);
  const [apiStatus, setApiStatus] = useState("200");
  const [buttonStatus, setButtonStatus] = useState(false);
  const [openChangePopup, setOpenChangePopup] = useState(false);
  const { username, password } = data;
  const [passwordShown, setPasswordShown] = useState(false);

  const onChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const getLoginApi = async () => {
    if (username === "") {
      setButtonStatus(true);
      setErrorMsg("Please enter the Username");
    } else if (password === "") {
      setButtonStatus(true);
      setErrorMsg("Please enter the Password");
    } else {
      localStorage.clear();
      setLoading(true);
      if (navigator.onLine) {
        const req_url = `${API_BASE_URL}Login?username=${username}&password=${password}`;
        //ToDo
        // const req_url = HelperFunctions.getLoginURL(username,password)
        axios
          .get(req_url)
          .then(async function (resp) {
            if (resp.status === 200) {
              const response = await resp.data;
              const emailId = await response.loginDetails[0]?.emailAddress;
              const token = await response?.token;
              const refreshToken = await response?.refreshToken;
              const assetTypes = await response?.assetTypes;
              const timestamp = new Date().getTime();
              const permissions = await response?.loginDetails[0]?.securityGroup;
              const logoUrl = await response?.loginDetails[0].logoUrl;
              localStorage.setItem("permissions", JSON.stringify(permissions));
              localStorage.setItem("logourl", logoUrl);
              let expiryDate = timestamp + 60 * 60 * 1000;
              localStorage.setItem("timestamp", expiryDate.toString());
              localStorage.setItem("userName", username);
              localStorage.setItem("password", password);
              localStorage.setItem("@tokenID", response?.token);
              localStorage.setItem("@partyID", response?.loginDetails[0]?.relationships[0]?.partyIdFrom);
              localStorage.setItem("@roleTypeID", response?.loginDetails[0]?.roles[0]?.roleTypeId);
              // const facilities = await response.data?.loginDetails[0]?.facilities;
              const facilities = response.loginDetails[0]?.facilities;
              dispatch({ type: "SET_FILTERED_RELATED_DATA", filterRelatedData: { name: 'facilities' } });
              localStorage.setItem("filterRelatedData", JSON.stringify(facilities));
              localStorage.setItem("email_check", emailId);
              localStorage.setItem("@tokenID", token);
              LocalStorageService.setRefreshTokenData(refreshToken);
              localStorage.setItem("assetTypes", assetTypes);
              localStorage.setItem("permissions", JSON.stringify(permissions));
              localStorage.setItem(
                "cityIds",
                JSON.stringify(facilities?.cityIds)
              );
              localStorage.setItem(
                "clusterIds",
                JSON.stringify(facilities?.clusterIds)
              );
              localStorage.setItem(
                "facilityIds",
                JSON.stringify(facilities?.facilityIds)
              );
              localStorage.setItem(
                "regionIds",
                JSON.stringify(facilities?.regionIds)
              );
              LocalStorageService.setAreaData(JSON.stringify(facilities?.areaIds));
              LocalStorageService.setLocationData(JSON.stringify(facilities?.locationIds));
              LocalStorageService.setSubLocationData(JSON.stringify(facilities?.subLocationIds));

              localStorage.setItem(
                "countryIds",
                JSON.stringify(facilities?.countryIds)
              );
              localStorage.setItem(
                "stateIds",
                JSON.stringify(facilities?.stateIds)
              );
              console.log("Login Called");
              LocalStorageService.setAreaData( JSON.stringify(facilities?.areaIds));
              LocalStorageService.setLocationData(JSON.stringify(facilities?.locationIds));
              LocalStorageService.setSubLocationData(JSON.stringify(facilities?.subLocationIds));

              localStorage.setItem("aoneLoginData", JSON.stringify(response));
              let firstNameLg = response.loginDetails[0].firstName;
              let lastNameLg = response.loginDetails[0].lastName;
              localStorage.setItem("firstNameH", firstNameLg);
              localStorage.setItem("lastNameH", lastNameLg);
              let loginDetails = response.loginDetails;
              let securityPermissions = loginDetails[0].securityGroup.map(
                (el: any) => el.permissionId
              );
              let permissionIds = securityPermissions.flat();
              let permissionsUnique = [...new Set(permissionIds)];
              // ToDo Hard Coded Values VooDoo
              if (HelperFunctions.isTnt(username)) {
                permissionsUnique.push("QR_GENERATION_VIEW");
                permissionsUnique.push("SETTINGS_VIEW");
              }
              // permissionsUnique.push('DB_VIEW')
              localStorage.setItem(
                "securityPermissions",
                JSON.stringify(permissionsUnique)
              );
              let securityGroupIds = loginDetails[0].securityGroup.map(
                (el: any) => el.groupId
              );
              let groupId = securityGroupIds.flat();
              let groupIdUnique = [...new Set(groupId)];
              localStorage.setItem(
                "securityGroupIds",
                JSON.stringify(groupIdUnique)
              );
              let roles = loginDetails[0].roles.map((el: any) => el.roleTypeId);
              let roleIds: any = roles.flat();
              let roleIdsUnique: any = [...new Set(roleIds)];
              localStorage.setItem("userRoles", JSON.stringify(roleIdsUnique));
              // token
              setData((prevData) => ({
                ...prevData,
                token: response?.token, // Update the token value with the new value
              }));
              console.log("Login Called");
              Login(data);
            }
          })
          .catch(function (error) {
            setApiStatus(error?.response?.data?.errorCode);
            setErrorMsg(error.response?.data?.error);
            setLoading(false);
            setButtonStatus(true);
            console.log("Login Called", error);
          });
      } else {
        setApiStatus("Network error");
        setErrorMsg("Please Check your Network Connection");
        setLoading(false);
        setButtonStatus(true);
      }
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    await getLoginApi();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
  };

  const [popupState, setPopupState] = useState(false);
  const openModalHandler = () => {
    setPopupState(true);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const closeModalHandler = () => {
    setPopupState(false);
  };

  return apiStatus === "500" ? (
    <Suspense fallback={<>Loading...</>}>
      <WronPage />
    </Suspense>
  ) : (
    <Suspense fallback={<>Loading...</>}>
      <div className="container">
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="subclassOne col-xs-12 col-lg-4">
              <div className="selectClassname">
                <select id="selectId">
                  <option value="English">English - US</option>
                </select>
              </div>
              {errorMsg && (
                <div
                  className={`alert alert-warnings alert-dismissible fade show`}
                  role="alert"
                >
                  {errorMsg}
                  <button
                    type="button"
                    onClick={() => {
                      setErrorMsg("");
                      setLoading(false);
                      setButtonStatus(false);
                    }}
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
              <div className="subContent">
                <div className="loginContent">
                  <h1 className="yourLogo"></h1>
                  <h6 className="welCome">Welcome!</h6>
                  <p className="pleasesignText">Please sign in to continue</p>
                  <input onChange={onChange} className="inputText" type="text" placeholder="Username" name="username" value={username} />
                  <br />
                  <div
                    className="inputTextOne"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <input onChange={onChange} className="inputTextInside" type={passwordShown ? "text" : "password"} aria-label="toggle password visibility" placeholder="Password" name="password" value={password} />
                    <div>
                      <img loading="lazy" className="eyeOn" onClick={togglePassword} src={passwordShown ? eyeOff : eyeOn} />
                    </div>
                  </div>
                  <div className="bottomClass">
                    {/* <button className="changePassword" onClick={openModalHandler}>
                    Change Password
                  </button> */}
                    <NavLink to={"/forgotpassword"}>
                      <div className="forgotPasswordContainer">
                        <p>Forgot Password</p>
                      </div>
                    </NavLink>
                    </div>
                  <button
                    disabled={loading || buttonStatus}
                    className={`signInbutton ${ buttonStatus ? "signInbuttonOpacity" : ""}`}
                    onClick={submitHandler}
                    type="submit"
                  >
                    {loading ? <Lottie options={defaultOptions} /> : "Sign in"}
                  </button>
                </div>
              </div>
              <p className="atsuyaClass">
                © {new Date().getFullYear()} Atsuya Technologies Pvt. Ltd.
              </p>
            </div>
            <LoginSubLayout />
          </div>
        </form>
      </div>
    </Suspense>
  );
}

export default React.memo(LoginPage);
