import moment from "moment";
import { getKeyForSelectedFilter } from "../Constants/constants";
import { Meter, MeterMap } from "../features/AHUSummary/types";
import { QueryClient } from "react-query";

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch: any) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

export const epochToDateFormRelease = (epoch: number) => {
  return moment(epoch).format("DD-MM-YYYY HH:MM");
};

/**
 * Rounds a numeric value to two decimal places.
 *
 * @param {number} value - The value to be rounded.
 * @returns {string} The rounded value as a string with two decimal places or "-" if the input is invalid.
 */

export const roundToTwoDecimalPlaces = (value: any) => {
  if (value === undefined || Number.isNaN(value) || value === null) {
    return "--";
  }
  try {
    return parseFloat(value).toFixed(2).toString();
  } catch (error) {
    return "--";
  }
};

/**
 * Rounds a numeric value to two decimal places.
 *
 * @param {number} value - The value to be rounded.
 * @returns {float} The rounded value as a float with two decimal places or 0.0 if the input is invalid.
 */

export const roundToTwoDecimalNumber = (value: any): number => {
  if (value === undefined || Number.isNaN(value)) {
    return 0.0;
  }
  try {
    const numericValue = typeof value === "string" ? parseFloat(value) : value;
    if (typeof numericValue === "number") {
      return parseFloat(numericValue.toFixed(2));
    } else {
      return 0.0;
    }
  } catch (error) {
    return 0.0;
  }
};

/**
 * Rounds a numeric value to decimal places.
 *
 * @param {number} value - The value to be rounded.
 * @returns {float} The rounded value as a float with two decimal places or 0.0 if the input is invalid.
 */

export const roundToDecimalNumber = (value: any, pointer: number): number => {
  if (value === undefined || Number.isNaN(value)) {
    return 0.0;
  }
  try {
    const numericValue = typeof value === "string" ? parseFloat(value) : value;
    if (typeof numericValue === "number") {
      return parseFloat(numericValue.toFixed(pointer));
    } else {
      return 0.0;
    }
  } catch (error) {
    return 0.0;
  }
};

// Change the string to comma seperated based on the value
export const commaSeperatedString = (value: string, decimal: number): string => {
  if (value === undefined || value === null) {
    return "--";
  }
  try {
    if (value.includes("k")) {
      const spillitedValue = value.split(" ");
      const decimalSplitted = spillitedValue[0].split(".");
      const commaSeperatedValue = formatNumberData(decimalSplitted[0], decimal);
      return decimalSplitted.length === 1 ? `${commaSeperatedValue} ${spillitedValue[1]}` : `${commaSeperatedValue}.${decimalSplitted[1]} ${spillitedValue[1]}`;
    } else {
      const decimalSplitted = value.split(".");
      const commaSeperatedValue = formatNumberData(decimalSplitted[0], decimal);
      return decimalSplitted.length === 1 ? commaSeperatedValue : `${commaSeperatedValue}.${decimalSplitted[1]}`;
    }
  } catch (error) {
    return "--";
  }
};

//color to rgb range
// Convert hex to RGB
export const hexToRgb = (hex: string) => {
  // Remove the hash if it's present
  const cleanedHex = hex.replace(/^#/, "");

  // Parse the hexadecimal values
  const bigint = parseInt(cleanedHex, 16);

  // Extract RGB values
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, 0.3)`; // 0.2 represents 20% transparency
};

// If the value is geater than 1000 divide the value/1000 and insert the 'k'
export const convertwattTokw = (value: any, posttext: string) => {
  try {
    console.log(value, typeof value, value > 1000, "::");
    let setData =
      value > 1000
        ? (value / 1000).toFixed(2) + ` ${posttext}`
        : value.toFixed(2) + " ";
    return setData.toString();
  } catch (error) {
    return "--";
  }
};

//utill function for the assetdetail page nul check
export function getCheckNullValue(value: null | number) {
  if (typeof value === "number") return value.toFixed(2);
  else return value;
}

export function getCheckNullValidation(value: null | number | string) {
  if (typeof value === "number") return value.toFixed(2);
  else return "--";
}

export function getCheckAbbrevationValue(value: null | number | string | any) {
  if (typeof value === null) return "--";
  else return `°${value}`;
}

// function to check if the number is greater than 1 if grater subrtact wholenumber minus number
export const subtractWholeNumber = (number: any, pointerValue: number) => {
  if (number === undefined) {
    return "--";
  } else if (typeof number === "number") {
    try {
      if (number > 1) {
        const wholeNumberPart = Math.floor(number);
        const result = wholeNumberPart - number;
        const fixed = result.toFixed(pointerValue);
        return fixed;
      } else {
        return number.toFixed(pointerValue);
      }
    } catch (error) {
      return "--";
    }
  } else return number;
};

/**
 *Summation of two numeric value.
 *
 * @param {number} value
 * @returns {float} The summation of the value as a float with two decimal places or 0.0 if the input is invalid.
 */
export const sumOfTwoDecimalPlaces = (value1: any, value2: any): number => {
  const data1 = roundToTwoDecimalNumber(value1);
  const data2 = roundToTwoDecimalNumber(value2);
  const sum = data1 + data2;

  // Use parseFloat to convert the string back to a number with two decimal places
  return parseFloat(sum.toFixed(2));
};

/**
 * Gets the key for a given value from the getKeyForSelectedFilter object.
 *
 * @param {string} value - The value for which to find the corresponding key.
 * @returns {string|null} - The key associated with the provided value, or null if not found.
 */
export function getFilterValueForKey(value: string): string | null {
  try {
    // Attempt to access the property using value as the key
    const key = Object.keys(getKeyForSelectedFilter).find(
      // @ts-ignore
      (k) => getKeyForSelectedFilter[k] === value
    );

    if (key !== undefined) {
      return key;
    } else {
      throw new Error("Key not found");
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}

/**
 *
 * @param type {string} - Asset type category
 * @returns {string} - Color for the disc connector node.
 */
export function getConnectorColor(type: string): string {
  const typeColors = {
    ENERGY: "rgba(255, 76, 48, 1)",
    WATER: "rgba(5, 117, 230, 1)",
    AIR: "rgba(68, 128, 180, 1)",
    FUEL: "rgba(229, 116, 12, 1)",
  };

  const color = typeColors[type] || "";
  return color;
}

type AssetColorData = {
  color: string;
  alpha: number;
};

type ColorMapping = {
  [key: string]: { color: string; alpha: number };
};

export function blendColors(
  color1: string,
  color2: string,
  alpha: number
): string {
  if (alpha === 1) {
    return color1;
  }

  const [r1, g1, b1] = color1.match(/\d+/g)!.map(Number);
  const [r2, g2, b2] = color2.match(/\d+/g)!.map(Number);

  const r = Math.round(r2 * (1 - alpha) + r1 * alpha);
  const g = Math.round(g2 * (1 - alpha) + g1 * alpha);
  const b = Math.round(b2 * (1 - alpha) + b1 * alpha);

  return `rgba(${r}, ${g}, ${b}, 1)`;
}

/**
 *
 * @param type {string} - Asset type
 * @param value {any} - Asset value
 * @param maxValue {any} - For grouped asset max value will be value of the asset category and for the ungrouped asset max value will be the max value among all the asset.
 * @param discType {string} - If disc is the asset category then the type will be parent otherwise it will be child.
 * @returns {object} - The color of the asset.
 */
export function getDiscBackgroundColor(
  type: string,
  value: any,
  maxValue: any,
  discType: string
) {
  if (discType === "parent") {
    const colorMapping: ColorMapping = {
      ENERGY: { color: "rgba(255, 159, 143, 1)", alpha: 1 },
      WATER: { color: "rgba(227, 237, 252, 1)", alpha: 1 },
      AIR: { color: "rgba(129, 164, 205, 1)", alpha: 1 },
      FUEL: { color: "rgba(244, 151, 65, 1)", alpha: 1 },
    };

    return colorMapping[type] || { color: "", alpha: 0 };
  }

  // Get the opacity of disc depends on the contribution for total.
  const value1 = roundToTwoDecimalNumber(value);
  const value2 = roundToTwoDecimalNumber(maxValue);
  const alpha = roundToTwoDecimalNumber(value1 / value2);

  const colorMap: { [key: string]: { color: string; alpha: number } } = {
    ENERGY: { color: `rgba(255, 76, 48, ${alpha})`, alpha },
    WATER: { color: `rgba(5, 117, 230, ${alpha})`, alpha },
    AIR: { color: `rgba(68, 128, 180, ${alpha})`, alpha },
    FUEL: { color: `rgba(229, 116, 12, ${alpha})`, alpha },
  };

  const result = colorMap[type] || { color: "", alpha: 0 };

  const blendedColor = blendColors(result.color, "rgb(255, 255, 255)", alpha);

  return { color: blendedColor, alpha: result.alpha };
}

/**
 *
 * @param value {string} - Asset value
 * @param maxValue {string} - Max value of the asset
 * @returns {object} - object of disc size based on the contribution of the asset and font size.
 */
export function getDiscDimension(value: any, maxValue: any) {
  const value1 = roundToTwoDecimalNumber(value);
  const value2 = roundToTwoDecimalNumber(maxValue);

  const contribution = roundToTwoDecimalNumber(value1 / value2);

  if (contribution >= 0.9) {
    return {
      discSize: "90px",
      fontSize: "12px",
    };
  } else if (contribution >= 0.8) {
    return {
      discSize: "85px",
      fontSize: "12px",
    };
  } else if (contribution >= 0.7) {
    return {
      discSize: "75px",
      fontSize: "12px",
    };
  } else if (contribution >= 0.6) {
    return {
      discSize: "80px",
      fontSize: "12px",
    };
  } else if (contribution >= 0.5) {
    return {
      discSize: "85px",
      fontSize: "12px",
    };
  } else if (contribution >= 0.4) {
    return {
      discSize: "70px",
      fontSize: "10px",
    };
  } else if (contribution >= 0.3) {
    return {
      discSize: "65px",
      fontSize: "10px",
    };
  } else if (contribution >= 0.2) {
    return {
      discSize: "55px",
      fontSize: "10px",
    };
  } else {
    return {
      discSize: "50px",
      fontSize: "10px",
    };
  }
}

export function convertMetersToMap(metersArray: Meter[]): MeterMap {
  const meterMap: MeterMap = new Map();
  if (!metersArray || metersArray.length === 0) {
    return meterMap;
  }

  metersArray.forEach((meter) => {
    const { meterTypeId, ...attributes } = meter;
    meterMap.set(meterTypeId, attributes);
  });

  return meterMap;
}

export function lastUpdatedTimeToString(epochTime: number) {
  const currentTime = moment();
  const updateTime = moment(epochTime);

  const minutesDiff = currentTime.diff(updateTime, "minutes");

  if (minutesDiff < 1) {
    return "few seconds ago";
  } else if (minutesDiff < 60) {
    return `${minutesDiff} min(s) ago`;
  } else {
    const hoursDiff = currentTime.diff(updateTime, "hours");
    return `${hoursDiff} hour(s) ago`;
  }
}

export function getFirstFacility() {
  let localData: any = localStorage.getItem("facilityIds");
  const facilityList = JSON.parse(localData);
  return facilityList[0]?.facilityId;
}

// Formatting milliseconds into  "n day(s) hh:mm:ss" format
export function millisecondsToTime(milliseconds: number) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  const formattedTime = `${
    days > 0 ? `${days} day${days > 1 ? "s " : " "}` : ""
  }${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")} mins`;
  return formattedTime;
}

export function convertUpperCaseLetter(e: string) {
  let uppercaseString = e.charAt(0).toUpperCase() + e.slice(1);
  if (uppercaseString.endsWith("y")) return uppercaseString.slice(0, -1) + "ie";
  else return uppercaseString;
}

export function convertTittleintoUpperCaseLetter(e: string) {
  let uppercaseString = e.charAt(0).toUpperCase() + e.slice(1);
  return uppercaseString;
}

export function checkPaginationVisible(e: number) {
  if (e > 5) return true;
  else return false;
}

export function checkIntialState(var1: string, var2: string) {
  if (var1 === var2) return "";
  else {
    let uppercaseString = var2.charAt(0).toUpperCase() + var2.slice(1);
    return uppercaseString;
  }
}
export const reportDropDownCustomStyle = {
  control: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    background: "#fff",
    border: "2px solid #a7b8f4",
    minHeight: "4.5vh",
    height: "4.5vh",
    width: "13vw",
    minWidth: "13vw",
    boxShadow: state.isFocused ? null : null,
    alignContent: "center",
  }),
  menuPortal: (base: any) => ({
    ...base,
    fontSize: "12px",
  }),

  menuList: (base: any) => ({
    ...base,
    fontSize: "12px",
    minHeight: "10vh !important",
    maxHeight: "30vh !important",
    "::-webkit-scrollbar": {
      width: "8px",
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#000",
    };
  },

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    fontSize: "12px",
    color: "#000",
    flexDirection: "row !important",
    flexWrap: "nowrap !important",
    overflowX: "auto !important",
    scrollbarWidth: "none",
    whiteSpace: "nowrap",
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: "4.5vh",
  }),

  multiValue: (provided: any) => ({
    ...provided,
    minWidth: "auto",
  }),

  // Hide scrollbars for Chrome/Safari/Opera
  valueContainerScrollbars: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
};


/**
 *
 * @param value : Value of the particular card meter
 * @param type : fixedAssetAssocWithTypeId of the Asset
 * @param maxValue : max temp defined in the Attribute List
 * @param minValue : min temp defined on the Attribute List
 * @param meterTypeId : meter id for the Asset
 * @returns : (Boolean) If the asset card meter value lies in the range
 */
export const checkIsAssetCompliance = (
  value: number,
  type: string,
  maxValue: any,
  minValue: any,
  meterTypeId: any
) => {
  if (meterTypeId !== "TEMP" || type !== "TEMP") {
    return true;
  } else if (maxValue === null || minValue === null) {
    return true;
  } else {
    const status = value < minValue || value > maxValue;
    return !status;
  }
};

/**
 * This function will remove the api call.
 * @param id : key defined for the particular query
 * @param queryClient : instance of the QueryClient
 */
export const cancelExistingQuery = (id: string, queryClient: QueryClient) => {
  queryClient
    .getQueryCache()
    .getAll()
    .forEach((query) => {
      if (query.queryKey.includes(id)) {
        queryClient.cancelQueries(query.queryKey);
        console.log("cancelled query");
      }
    });
};

export const isUndefined = (value: any) => {
  if (value === undefined) return true;
  else if (value === null) return true;
  else return false;
};

export const formatMeterValue = (
  value: any,
  meterTypeId: string,
  pointerValue: number
) => {
  if (meterTypeId === "POWER_FACTOR") {
    const result = subtractWholeNumber(value, pointerValue);
    return result;
  }

  const temp = Number(value);
  if(typeof value === "boolean" || Number.isNaN(temp)) {
    return value;
  }

  const roundValue = roundToDecimalNumber(value, pointerValue);
  const result = commaSeperatedString(roundValue.toString(), pointerValue);
  return result;
};

export const getCardAbbreviation = (value: any) => {
  if (value === null || value === undefined) {
    return value;
  }
  const temperatureDegree = ["C", "F", "K"];
  if (temperatureDegree.includes(value.toUpperCase())) {
    return <span dangerouslySetInnerHTML={{ __html: `&#176;${value}` }} />;
  } else {
    return value;
  }
};

export const currentEpoch = (): number => {
  const currentDate = new Date();
  const epochSeconds = Math.floor(currentDate.getTime() / 1000);
  return epochSeconds;
};

export const formatNumberData = (value: any, decimal: number) => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  const timeZoneToLocaleMap = {
    'Asia/Calcutta': 'en-IN',   // India
    'America/Chicago': 'en-US',  // US
    'Europe/London': 'en-GB',   // UK
  };
    const locale = timeZoneToLocaleMap[timeZone] || 'en-US';
    const formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: decimal,
    });
  
  return formatter.format(value).toLocaleString();
  } catch (err) {
    return value;
  }
}