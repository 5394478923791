import { ASSET_CATEGORY_HIERARCHY, FACILITY_HIERARCHY } from '../../../Constants/constants';
import filterCloseIcon from '../assets/filterCloseIcon.svg'

function FilterChipsButton({element,updateSearchList,handleSelectedFilter, filterHierarchyData, filterDataListRef}) {
    return <div className='chipContainer'>
        <p>{element.group}: {element.name}</p>
        <img onClick={() => {
            handleSelectedFilter(element, ASSET_CATEGORY_HIERARCHY.includes(element.group) ? 1 : FACILITY_HIERARCHY.includes(element.group) ? 2 : 0);
            !['Status', 'Asset Type', 'Sub Locations'].includes(element.group) &&
                filterHierarchyData(ASSET_CATEGORY_HIERARCHY.includes(element.group) ? 1 : FACILITY_HIERARCHY.includes(element.group) ? 2 : 0, element.group, ['', '']);
            setTimeout(() => {
                updateSearchList(filterDataListRef)
            }, 100);
        }} src={filterCloseIcon} />
    </div>
}

export default FilterChipsButton